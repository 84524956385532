const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        promotion: [],
        singlePromo: undefined,
    }),
    getters: {
        Promotion: (state) => state.promotion,
        SinglePromo: (state) => state.singlePromo,
    },
    mutations: {
        SET_PROMOTION(state, data) {
            state.promotion = data;
        },
        action5kPromo() {},
        SET_SINGLE_PROMO(state, data) {
            state.singlePromo = data;
        },
    },
    actions: {
        async claim_promotion7(context, input) {
            return axios.post('/promotion/promotion-7', input);
        },
        async getPromotionInfo({ commit }) {
            return axios.get('promotion/list').then((res) => {
                if (res && res.status) {
                    commit('SET_PROMOTION', res.data);
                }
            });
        },
        async claim_airdrop() {
            return axios.get('/promotion/claim-airdrop2');
        },
        async get5kPromo({ commit }) {
            const res = await axios.get('promotion/promotion-with-id', {
                params: {
                    promotion_id: 8,
                },
            });
            console.log(res);
            if (res.status) {
                commit('SET_SINGLE_PROMO', res);
            }
            return res;
        },
    },
};
