<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            showPassword: false,
            password: null,
            email: null,
            pending: false,
            unsubscribe: null,
            userInput: null,
            walletAddress: [],
        };
    },

    computed: {
        ...mapGetters({
            isNetWork: 'contract/isNetWork',
            Address: 'info/Address',
        }),
        ChainID() {
            if (this.isNetWork === 'testnet') {
                return 97;
            }
            return 56;
        },
        netWorkConfig() {
            const testNet = {
                chainId: '0x61',
                chainName: 'Smart Chain - Testnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://data-seed-prebsc-2-s2.binance.org:8545/',
                    'https://data-seed-prebsc-1-s1.binance.org:8545/',
                    'https://data-seed-prebsc-5-s5.binance.org:8545/',
                    'https://data-seed-prebsc-3-s3.binance.org:8545/',
                    'https://data-seed-prebsc-4-s4.binance.org:8545/',
                ],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
            };
            const mainNet = {
                chainId: '0x38',
                chainName: 'Smart Chain',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/',
                    'https://bsc-dataseed1.defibit.io/',
                    'https://bsc-dataseed1.ninicoin.io/',
                ],
                blockExplorerUrls: ['https://bscscan.com'],
            };
            if (this.isNetWork === 'testnet') {
                return testNet;
            }
            return mainNet;
        },
    },

    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },

        loginEmail() {
            const isEmail = this.userInput
                .toLowerCase()
                .trim()
                .match(/^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]+$/);
            const isPhone = this.userInput
                .trim()
                .match(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/);
            if (this.pending) {
                return;
            }
            this.pending = true;
            if (isEmail) {
                this.$store
                    .dispatch('auth/LoginEmail', {
                        user: this.userInput,
                        password: this.password,
                    })
                    .finally(() => {
                        this.pending = false;
                    });
            } else if (isPhone) {
                this.$store
                    .dispatch('auth/loginPhone', {
                        phone: this.userInput,
                        password: this.password,
                    })
                    .finally(() => {
                        this.pending = false;
                    });
            }
        },
        async onConnectMetaMask() {
            let address = [];
            try {
                this.walletAddress = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }
            if (window.ethereum.chainId !== this.ChainID) {
                const params = this.netWorkConfig;
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    })
                    .then(() => console.log('add network success'))
                    .catch(() => {
                        this.showNotification = true;
                        this.message = {
                            content:
                                'Please switch To The  Binance Smartchain Network!',
                            failed: true,
                            title: 'Oops...',
                        };
                        window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: this.ChainID }],
                        });
                    });
            }
            if (this.walletAddress.length < 0) {
                return false;
            }
            this.$store.dispatch('auth/LoginAddress', {
                address: this.walletAddress[0],
            });
            this.$store.commit('info/SET_ADDRESS', {
                address: this.walletAddress[0],
            });

            // this.$store.dispatch('auth/getSignature', {
            //     address: this.walletAddress[0],
            // });
            console.log('Address:', this.walletAddress);
            return true;
        },
        async onLogin() {
            console.log('This func is running');
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [this.Address, this.$store.state.auth.sign],
            });
            console.log('Signature:', txData);
            this.$store.dispatch('auth/LoginAddress', {
                address: this.Address,
                sign: txData,
            });
        },
        async changTab() {
            try {
                this.walletAddress = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }
            if (window.ethereum.chainId !== this.ChainID) {
                const params = this.netWorkConfig;
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    })
                    .then(() => console.log('add network success'))
                    .catch(() => {
                        this.showNotification = true;
                        this.message = {
                            content:
                                'Please switch To The  Binance Smartchain Network!',
                            failed: true,
                            title: 'Oops...',
                        };
                        window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: this.ChainID }],
                        });
                    });
            }
            if (this.walletAddress.length < 0) {
                return false;
            }
            this.$store.commit('info/SET_ADDRESS', {
                address: this.walletAddress[0],
            });
            this.$store.dispatch('auth/registerWallet', {
                address: this.walletAddress[0],
            });

            // this.$store.dispatch('auth/getSignature', {
            //     address: this.walletAddress[0],
            // });
            console.log('Address:', this.walletAddress);
            router.push({ path: '/register-wallet' });
            return true;
        },
    },
    mounted() {
        if (this.$route.query.status && this.$route.query.message) {
            if (this.$route.query.status === 'true') {
                this.$toastr.s(this.$route.query.message, 'Success');
            } else {
                this.$toastr.e(this.$route.query.message, 'Error');
            }
        }
        if (this.$route.query.email) {
            this.email = this.$route.query.email;
        }
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            switch (mutation.type) {
                case 'auth/GET_SIGNATURE_SUCCESS':
                    this.onLogin({
                        address: state.info.address,
                        signature: state.auth.sign,
                    });
                    break;
            }
        });
        console.log('create run');
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <div class="login-container">
        <form @submit.prevent>
            <div class="img">
                <img src="../../../assets/images/logo/logoV3.png" alt="" />
            </div>
            <div class="inputs">
                <input
                    type="text"
                    :placeholder="$t('emailOrPhone')"
                    v-model="userInput"
                    autocomplete="email"
                    id="email"
                />
                <div>
                    <div class="input-pw">
                        <input
                            :type="showPassword ? 'text' : 'password'"
                            :placeholder="$t('password')"
                            id="password"
                            v-model="password"
                            autocomplete="current-password"
                        />
                        <img
                            v-if="!showPassword"
                            @click="toggleShow"
                            class="eye-open"
                            src="../../../assets/images/icons/eye.svg"
                            alt=""
                        />
                        <img
                            v-else
                            @click="toggleShow"
                            class="eye-close"
                            src="../../../assets/images/icons/eye-close.svg"
                            alt=""
                        />
                    </div>
                    <div class="quicklinks">
                        <router-link to="/forgot">
                            <strong>
                                {{ $t('forgotPassword') }}
                            </strong>
                            <!-- <strong>Reset</strong> -->
                        </router-link>
                        <router-link class="router-link" to="/active-mail">
                            <strong>{{ $t('activeAccount') }}</strong>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="buttons">
                <button
                    @click="loginEmail"
                    :class="{ pending: pending }"
                    :disabled="pending"
                >
                    {{ $t('signIn') }}
                </button>
                {{ $t('or') }}
                <button @click="onConnectMetaMask">
                    <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.97633 5.375H19.9763C20.1515 5.37491 20.3266 5.38603 20.5004 5.40828C20.4415 4.99473 20.2994 4.5974 20.0828 4.24024C19.8662 3.88308 19.5795 3.57348 19.24 3.33012C18.9005 3.08676 18.5152 2.91467 18.1074 2.82423C17.6996 2.7338 17.2777 2.72688 16.8671 2.8039L4.53102 4.91H4.51695C3.74261 5.05807 3.05401 5.49618 2.5918 6.13484C3.28817 5.63952 4.12177 5.37389 4.97633 5.375Z"
                            fill="#6DCEFF"
                        />
                        <path
                            d="M19.9766 6.5H4.97656C4.18118 6.50087 3.41862 6.81722 2.8562 7.37964C2.29378 7.94206 1.97743 8.70462 1.97656 9.5V18.5C1.97743 19.2954 2.29378 20.0579 2.8562 20.6204C3.41862 21.1828 4.18118 21.4991 4.97656 21.5H19.9766C20.7719 21.4991 21.5345 21.1828 22.0969 20.6204C22.6593 20.0579 22.9757 19.2954 22.9766 18.5V9.5C22.9757 8.70462 22.6593 7.94206 22.0969 7.37964C21.5345 6.81722 20.7719 6.50087 19.9766 6.5ZM17.75 15.5C17.4533 15.5 17.1633 15.412 16.9166 15.2472C16.67 15.0824 16.4777 14.8481 16.3642 14.574C16.2506 14.2999 16.2209 13.9983 16.2788 13.7074C16.3367 13.4164 16.4796 13.1491 16.6893 12.9393C16.8991 12.7296 17.1664 12.5867 17.4574 12.5288C17.7483 12.4709 18.0499 12.5006 18.324 12.6142C18.5981 12.7277 18.8324 12.92 18.9972 13.1666C19.162 13.4133 19.25 13.7033 19.25 14C19.25 14.3978 19.092 14.7794 18.8107 15.0607C18.5294 15.342 18.1478 15.5 17.75 15.5Z"
                            fill="#6DCEFF"
                        />
                        <path
                            d="M2 12.6641V8C2 6.98422 2.5625 5.28125 4.51484 4.91234C6.17188 4.60156 7.8125 4.60156 7.8125 4.60156C7.8125 4.60156 8.89062 5.35156 8 5.35156C7.10938 5.35156 7.13281 6.5 8 6.5C8.86719 6.5 8 7.60156 8 7.60156L4.50781 11.5625L2 12.6641Z"
                            fill="#6DCEFF"
                        />
                    </svg>
                    {{ $t('connectWallet') }}
                </button>
            </div>
            <p>
                {{ $t('dontHaveAccount') }}
                <router-link class="router-link" to="/register">
                    <strong style="color: #02c0c0">{{
                        $t('registerAccount')
                    }}</strong>
                </router-link>
            </p>
            <!-- <p>
                Register with your
                <strong @click="changTab">Wallet</strong>
            </p> -->
        </form>
    </div>
</template>
<style lang="scss">
.login-container {
    background: linear-gradient(#3e84c6, #1a446b);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    form {
        flex-basis: 400px;

        .img {
            display: flex;
            justify-content: center;

            > img {
                max-width: 100%;
                width: 200px;
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin: 0 auto;
            margin-top: 1.5rem;
            input {
                width: 100%;
                color: #fff;
            }
            .input-pw {
                position: relative;

                img {
                    position: absolute;
                    top: 50%;
                    right: 1rem;
                    transform: translateY(-50%);
                    width: 24px;
                    cursor: pointer;
                }
            }

            input {
                padding: 0 1rem;
                border: 0.5px solid #e4e4e4;
                border-radius: 8px;
                height: 40px;
                box-sizing: border-box;
                background-color: #6a93b9;
            }

            input:focus-visible {
                outline: none;
            }

            input::placeholder {
                color: #fff;
                font-size: 18px;
                font-style: italic;
                font-weight: 400;
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1rem;
            gap: 5px;
            color: #fff;

            button {
                padding: 8px;
                width: 100%;
                border-radius: 72px;
                text-transform: uppercase;
                font-size: 18px;
                transition: 0.5s;
                outline: none;

                &:hover {
                    opacity: 0.5;
                }

                &.pending {
                    filter: grayscale(1);
                }
            }

            button:nth-child(1) {
                background: linear-gradient(to right, #1775b8, #91c4e9);
                color: #fff;
                font-weight: 600;
            }

            button:nth-child(2) {
                border: 1px solid #8bcff1;
                color: #6dceff;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 7px;

                font-weight: 600;
            }
        }

        span {
            a {
                color: #fff;
                transition: 0.5s;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        p {
            margin-top: 1rem;
            text-align: center;
            font-size: 16px;
            color: #fff;

            .router-link {
                transition: 0.5s;
                &:hover {
                    opacity: 0.5;
                    color: inherit;
                }
            }
        }

        .forgot {
            text-align: right;
            font-size: 14px;
        }
    }

    @media (max-width: 1201px) {
        form {
            .inputs {
                width: 100%;
            }
        }
    }

    @media (max-width: 990px) {
        form {
            .inputs {
                input::placeholder {
                    font-size: 16px;
                }

                .input-pw {
                    img {
                        width: 25px;
                    }
                }
            }

            .buttons {
                button {
                    width: 100%;
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 376px) {
        padding: 5rem 1rem 3rem 1rem;
    }
}
.quicklinks {
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding-top: 4px;
    a {
        transition: 0.2s;
        font-size: 14px;
        &:hover {
            color: inherit;
            opacity: 0.6;
        }
    }
}
</style>
